import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { AuthService } from '@app/core/auth.service';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { LegalDocumentsService } from '@app/core/legal-documents';
import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import { GraphNavigationService } from '@app/registration/graph/graph-navigation.service';
import { PatientRegistrationStatusGraphqlService } from '@app/registration/patient-registration-status-graphql.service';

import { HasClaimedAccessKeyGraphqlService } from './has-claimed-access-key-graphql.service';
import { LinksService } from './links.service';

@Injectable({
  providedIn: 'root',
})
export class TosGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private attemptedPathService: AttemptedPathService,
    private membershipService: MembershipService,
    private router: Router,
    private legalDocumentsService: LegalDocumentsService,
    private links: LinksService,
    private patientRegistrationStatusGraphqlService: PatientRegistrationStatusGraphqlService,
    private launchDarklyService: LaunchDarklyService,
    private hasClaimedAccessKeyGraphqlService: HasClaimedAccessKeyGraphqlService,
    private graphNavigationService: GraphNavigationService,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // This guard runs simultaneously with the AuthGuardService, so it cannot assume that the user is authenticated
    return this.authService
      .isLoggedIn$()
      .pipe(switchMap(isLoggedIn => (isLoggedIn ? this.runTosCheck(state.url) : observableOf(false))));
  }

  private runTosCheck(desiredPath: string) {
    return combineLatest([
      this.legalDocumentsService.getForSelf().valueChanges,
      this.membershipService.getMembership(),
      this.fetchPatientRegistrationStatus(),
      this.fetchTosModalNotificationEnabled(),
      this.hasClaimedChartKey(),
    ]).pipe(
      map(([tos, membership, patientRegCompletionStatus, tosNotificationEnabled, hasClaimedKey]) => {
        const unsignedDocs = tos.filter(doc => !doc.signed);
        if (
          unsignedDocs.length &&
          this.canTosCheckUser(membership, patientRegCompletionStatus) &&
          (!tosNotificationEnabled || hasClaimedKey)
        ) {
          this.attemptedPathService.setAttemptedPath(desiredPath);
          if (hasClaimedKey) {
            this.graphNavigationService.navigate();
          } else {
            this.router.navigateByUrl(this.links.termsOfService, { replaceUrl: true });
          }
          return false;
        }
        return true;
      }),
    );
  }

  private fetchTosModalNotificationEnabled(): Observable<boolean | undefined> {
    return this.launchDarklyService.featureFlag$(FeatureFlags.HOMESCREEN_NOTIFY_TERMS_OF_SERVICE, false);
  }

  private fetchPatientRegistrationStatus(): Observable<boolean | undefined> {
    return this.launchDarklyService
      .featureFlag$(FeatureFlags.LIMITED_ACCESS_ON_REG_RESUMPTION_SKIP_TOS_CHECK, false)
      .pipe(
        switchMap((skipTosCheckOnLimitedAccessRegResumption: boolean) =>
          skipTosCheckOnLimitedAccessRegResumption
            ? this.patientRegistrationStatusGraphqlService
                .fetch()
                .pipe(map(result => result?.data?.patient?.isRegComplete))
            : observableOf(true),
        ),
      );
  }

  private canTosCheckUser(membership: Membership, patientIsRegComplete: boolean | undefined) {
    if (membership.isGuest() && !patientIsRegComplete) {
      return false;
    }

    return membership.isActive;
  }

  private hasClaimedChartKey(): Observable<boolean | undefined> {
    return this.hasClaimedAccessKeyGraphqlService
      .fetch()
      .pipe(map(response => response?.data?.patient?.hasClaimedAccessKey));
  }
}
