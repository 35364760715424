<div *ngIf="!loading" class="container my-4">
  <div class="row justify-content-center pb-4">
    <div class="col-lg-8">
      <div *ngIf="currentStep === PaymentStep.COPAY_REQUIRED">
        <om-copay-required
          [sourceProp]="sourceProp"
          [showSkip]="showSkip"
          [copay]="copay"
          (back)="goBack()"
          (add)="goToStep(PaymentStep.ADD_METHOD)"
          (skip)="goToDestination()"
          (analytics)="trackAnalytics($event)"
        ></om-copay-required>
      </div>
      <div *ngIf="currentStep === PaymentStep.ADD_METHOD">
        <om-add-payment-method
          [sourceProp]="sourceProp"
          [showSkip]="showSkip"
          [canSetDefault]="canSetDefault"
          [currentPaymentMethod]="currentPaymentMethod"
          (back)="goBack()"
          (save)="goToDestination()"
          (skip)="goToDestination()"
          (analytics)="trackAnalytics($event)"
        ></om-add-payment-method>
      </div>
    </div>
  </div>
</div>
